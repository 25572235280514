import React from "react";
import { Routes, Route } from "react-router-dom";
import Math10 from './Math10/Math10'
const App = () => { 

  return (
    <div>
       <Routes>
        <Route path="/" exact element={<Math10 />}/> 
        <Route path="/M10uyxkW" element={<Math10 text='Real Numbers' />} />
        <Route path="/M10xm4v2" element={<Math10 text='Polynomials' />} />
        <Route path="/M10kxbIT" element={<Math10 text='Pair of Linear Equations' />} />
        <Route path="/M101I4gs" element={<Math10 text='Quadratic Equations' />} />
        <Route path="/M10OgvDN" element={<Math10 text='Arithemetic Progression' />} />
        <Route path="/M100V94O" element={<Math10 text='Co-ordinate Geometry' />} />
        <Route path="/M10VYUo5" element={<Math10 text='Triangles' />} />
        <Route path="/M106ATcJ" element={<Math10 text='Circles' />} />
        <Route path="/M10looIJ" element={<Math10 text='Contructions' />} />
        <Route path="/M10YGSv5" element={<Math10 text='Trigonometric Ratios' />} />
        <Route path="/M10qyc5G" element={<Math10 text='Trigonometric Identities' />} />
        <Route path="/M10sgxNb" element={<Math10 text='Heights and Distances' />} />
        <Route path="/M10aAYGx" element={<Math10 text='Areas Related to Circles' />} />
        <Route path="/M107dEGa" element={<Math10 text='Surface areas and Volumes' />} />
        <Route path="/M109waEC" element={<Math10 text='Statistics' />} />
        <Route path="/M1059nmX" element={<Math10 text='Probability' />} />
        <Route path="/M10hKq6L" element={<Math10 text='RD Sharma Class X' />} />
        {/* <Route path="/:any" element={<Math10 text='RD Sharma Class X' />} /> */}
      </Routes>
    </div>
  );
};

export default App;
